#logo_principal_navbar{
    position: absolute;
    left: 0px;
    top: 0px;
}

details > summary {
    list-style-type: '';
    border: none;
}

details[open] > summary {
    list-style-type: '';
    border: none;
}

details summary::-webkit-details-marker {
     display:none;
}

details[open] {
    border: none;
}

summary {
    border: none;
    outline:none;
    
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
    
}

ul > li > a {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}
@media (max-width: 1100px){
    ul > li > a {
        font-size: 14px;
    }
}
ul > li > button {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}
.hover-menu:hover{
    color: #F4901E;
}