@font-face {
    font-family: 'TuskerGrotesk-6500Medium';
    src: local('TuskerGrotesk-6500Medium.woff'), url(../fonts/Tusker_Grotesk/TuskerGrotesk-6500Medium.woff) format('woff');
/* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
@font-face {
    font-family: 'Sora-ExtraBold';
    src: local('Sora-ExtraBold.ttf'), url(../fonts/Sora/Sora-ExtraBold.ttf) format('truetype');
/* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
@font-face {
    font-family: 'Sora-VariableFont_wght';
    src: local('Sora-VariableFont_wght.ttf'), url(../fonts/Sora/Sora-VariableFont_wght.ttf) format('truetype');
/* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

.tusker_grotesk_6500_medium {
    font-family: 'TuskerGrotesk-6500Medium';
    font-weight: 500;
    font-style: normal;
}
.sora-extra-bold {
    font-family: 'Sora-ExtraBold';
    font-weight: 800;
    font-style: normal;
}
.sora{
    font-family: 'Sora-VariableFont_wght';
    font-style: normal;
}
.sora-400{
    font-family: 'Sora-VariableFont_wght';
    font-weight: 400;
    font-style: normal;
}