#fullColor{
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 3000px;
    max-height: 1241px;
    background-color: #701BB5;
    z-index: -1;
}
#titleFormulario{
    font-size: 48px;
    font-family: 'Darker Grotesque', sans-serif;
    font-weight: 800;
    font-style: normal;
    font-feature-settings: 'pnum' on, 'lnum' on;
}
#fullColoryellow{
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 3000px;
    max-height: 1241px;
    background-color: #F4901E;
    z-index: -1;
}